<template>
    <chooser
        title='בחירת שאלות לצפייה'
        tooltipIntent='לצפות בהן'
        tooltipAction='{click} "המשך" כדי לצפות בהן'
        @submit='submit'
        submitText='המשך'
    />
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import chatModule, { setQuestions } from '../../Chat/module';
import { getSelected } from '../Chooser/helpers';
import { setToast } from '@/store';

export default defineComponent({
    methods: {
        async submit() {
            const ids = getSelected();
            if(!ids)
                return;

            try{
                const { body: questions } = await this.$request('/questions', 'put', { ids });

                if(!this.$store.hasModule('chat'))
                    this.$store.registerModule('chat', chatModule);

                setQuestions(questions);
                this.$router.push('/questions/view');
            }catch(error){
                console.error(error);
                setToast({ text: 'משהו השתבש. נסו שוב', type: 'error' })
            }
        },
    }
});
</script>